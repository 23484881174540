import React from 'react';
import li from '../../img/linkedin.svg';
import gh from '../../img/github.svg';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <a href='https://www.linkedin.com/in/blakehearn/' target='_blank' rel='noopener noreferrer'>
        <img src={li} alt='LinkedIn logo'/>
        <p>blakehearn</p>
      </a>
      <a href='https://github.com/bhearn7' target='_blank' rel='noopener noreferrer'>
        <img src={gh} alt='GitHub logo'/>
        <p>@bhearn7</p>
      </a>
    </div>
  );
}

export default Footer;