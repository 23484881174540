import React from 'react';
import './Header.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darkmode: false
    }
    this.toggleDarkmode = this.toggleDarkmode.bind(this);
  }
  toggleDarkmode() {
    this.setState({
      darkmode: !this.state.darkmode
    });
    this.props.appDarkmode();
  }
  render() {
    return (
      <div className='header'>
        <div className='hamburger' onClick={this.props.appDrawer}>
          <div className='hamburger-line'></div>
          <div className='hamburger-line'></div>
          <div className='hamburger-line'></div>            
        </div>
        <div className='title'>
          <h1>Blake Hearn</h1>
          <h3>Software Engineer</h3>
        </div>
        <div className='darkmode-btn-wrapper'>
          <h5>Dark</h5>
          <div className={!this.state.darkmode ? 'darkmode-btn-oval' : 'darkmode-btn-oval darkmode-btn-oval-on'} onClick={this.toggleDarkmode}>
            <h6 className={!this.state.darkmode ? 'darkmode-btn-label' : 'darkmode-btn-label darkmode-btn-label-on'}>{this.state.darkmode ? 'ON' : 'OFF'}</h6>
            <div className={!this.state.darkmode ? 'darkmode-btn-circle' : 'darkmode-btn-circle darkmode-btn-circle-right'} onClick={this.toggleDarkmode}></div>
          </div>
          <h5>Mode</h5>
        </div>
      </div>
    );
  }
}

export default Header;