import React from 'react';
import gh from '../../img/github.svg';
import ghb from '../../img/github-black.svg';
import ij from '../../img/isejingu.png';
import hangman from '../../img/hangman.png';
import blakeflix from '../../img/blakeflix.png';
import './Projects.css';

const Projects = (props) => {
  return (
    <div className='projects'>
      <h2>Projects</h2>
      <div className='project'>
        <div className='project-header'>
          <h3>Ise Jingu</h3>
          <a href='https://github.com/bhearn7/' target='_blank' rel='noopener noreferrer'>
            <img src={props.darkmode ? gh : ghb} alt='Link to GitHub repo'/>
          </a>
        </div>
        <a href='https://focused-haibt-28df1b.netlify.com/' target='_blank' rel='noopener noreferrer'>
          <img className='project-img' src={ij} alt='Link to live project'/>
        </a>
        <p>Web app that analyzes user proficiency through coding quizzes and provides a path for the user to learn/refresh their knowledge. Technologies used: Neo4j, ReactJS, Material UI, Node.js</p>
      </div>
      <div className='project'>
        <div className='project-header'>
          <h3>Netflix Clone</h3>
          <a href='https://github.com/bhearn7/mern-proj' target='_blank' rel='noopener noreferrer'>
            <img src={props.darkmode ? gh : ghb} alt='Link to GitHub repo'/>
          </a>
        </div>
        <a href='https://epic-dijkstra-49e3ac.netlify.com/' target='_blank' rel='noopener noreferrer'>
          <img className='project-img' src={blakeflix} alt='Link to live project'/>
        </a>
        <p>Web app that includes a custom API with CRUD functionality. Technologies used: MongoDB, Express, ReactJS, Node.js</p>
      </div>
      <div className='project'>
        <div className='project-header'>
          <h3>Hangman</h3>
          <a href='https://github.com/bhearn7/hangman' target='_blank' rel='noopener noreferrer'>
            <img src={props.darkmode ? gh : ghb} alt='Link to GitHub repo'/>
          </a>
        </div>
        <a href='https://bhearn7.github.io/hangman/' target='_blank' rel='noopener noreferrer'>
          <img className='project-img' src={hangman} alt='Link to live project'/>
        </a>
        <p>Browser-based game that utilizes API queries, regular expressions, responsive design, and keyframe animations. Technologies used: JavaScript, CSS, HTML</p>
      </div>
      <h2 className='bottom-extra'>More projects coming soon...</h2>
    </div>
  );
}

export default Projects;