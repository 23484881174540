import React from 'react';
import hs from '../../img/headshot.jpg';
import html5 from '../../img/html5.png';
import css3 from '../../img/css3.png';
import js from '../../img/javascript.png';
import react from '../../img/react.png';
import node from '../../img/node.png';
import python from '../../img/python.png';
import postgres from '../../img/postgres.png';
import mongo from '../../img/mongo.png';
import './Home.css';

const Home = (props) => {
  return (
    <div className='home'>
      <img id='headshot' src={hs} alt='Blake Hearn headshot'/>
      <h1>Hi, I'm Blake!</h1>
      <h3>I'm a software developer in the DMV area.</h3>
      <h3>This is my website. Feel free to browse around using the button in the top-left corner.</h3>
      <div id='logo-container'>
        <img src={html5} alt='HTML5 Logo'/>
        <img src={css3} alt='CSS3 Logo'/>
        <img src={js} alt='JavaScript Logo'/>
        <img src={react} alt='React Logo'/>
        <img src={node} alt='NodeJS Logo'/>
        <img src={python} alt='Python Logo'/>
        <img src={postgres} alt='PostgreSQL Logo'/>
        <img src={mongo} alt='MongoDB Logo'/>
      </div>
      <h3>When I'm not coding, I'm a poker player, car enthusiast, and golfer.</h3>
    </div>
  );
}

export default Home;