import React from 'react';
import { Link } from "react-router-dom";
import c from '../../img/close.png';
import './Drawer.css';

const Drawer = (props) => {
  return (
    <div className={!props.status ? 'drawer' : 'drawer drawer-open'}>
      <img src={c} alt='exit drawer button' onClick={props.appDrawer}/>
      <div onClick={props.appDrawer}>
        <Link to='/'>Home</Link>
        <Link to='/projects'>Projects</Link>
        <Link to='/resume'>Resume</Link>
      </div>
    </div>
  );
}

export default Drawer;