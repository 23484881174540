import React from 'react';
import { Route } from "react-router-dom";
import Drawer from './components/Drawer/Drawer';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects';
import Resume from './pages/Resume/Resume';
import Footer from './components/Footer/Footer';
import './App.css';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      darkmode: false,
      drawerOpen: false
    };
    this.toggleDarkmode = this.toggleDarkmode.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  toggleDarkmode() {
    this.setState({
      darkmode: !this.state.darkmode
    });
  }
  toggleDrawer() {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  }
  render() {
    return (
      <div className='app'>
        <Drawer status={this.state.drawerOpen} appDrawer={this.toggleDrawer} />
        <Header appDarkmode={this.toggleDarkmode} appDrawer={this.toggleDrawer} />
        <main className={!this.state.darkmode ? 'main' : 'main main-dark'}>
          <Route path='/' exact component={Home}/>
          <Route path='/projects' exact render={() => <Projects darkmode={this.state.darkmode} />}/>
          <Route path='/resume' exact component={Resume}/>
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;