import React from 'react';
import resume from '../../img/resume.pdf';
import resumeScreenshot from '../../img/resume-screenshot.png';
import './Resume.css';

const Resume = () => {
  return (
    <div className='resume'>
      <h2>I am currently open to new opportunities. Please reach out to me!</h2>
      <a href={resume} target="_blank" rel="noopener noreferrer">
        <img src={resumeScreenshot} rel="pdf" alt="pdf" />
      </a>
    </div>
  );
}

export default Resume;